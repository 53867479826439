import {defineStore} from "pinia"
import ApiService from "../service/ApiService"
import {data} from "uikit/src/js/util";

export const useDeliveryAddressStore = defineStore('DeliveryAddressStore', {
    state: () => ({
        listData: {},
        tempDeliveryAddress: {}
    }),
    getters: {
        getItems(state) {
            return state.listData.items ?? [];
        },
        getCurrentDeliveryAddressId(state) {
            return state.listData.current_delivery_address_id ?? 0;
        },
        getCountryCodeList(state) {
            return state.listData.country_code_list ?? [];
        },
        getTempDeliveryAddress(state) {
            return state.tempDeliveryAddress ?? [];
        },
        getTempDeliveryAddressOptionString(state) {
            let optionString = ''
            if(state.tempDeliveryAddress.Company) {
                optionString += state.tempDeliveryAddress.Company + ', '
            }
            if(state.tempDeliveryAddress.Firstname) {
                optionString += state.tempDeliveryAddress.Firstname + ' '
            }
            if(state.tempDeliveryAddress.Lastname) {
                optionString += state.tempDeliveryAddress.Lastname + ', '
            }
            if(state.tempDeliveryAddress.Street) {
                return optionString + state.tempDeliveryAddress.Street + ', ' + state.tempDeliveryAddress.Zip + ' ' + state.tempDeliveryAddress.City;
            }
            return null;
        },
        getTempDeliveryAddressIsSelected(state) {
            return state.tempDeliveryAddress.isSelected ?? [];
        },
        hasTempDeliveryAddress(state) {
            return this.getTempDeliveryAddressOptionString !== null;
        }
    },
    actions: {
        loadDeliveryAddressList() {
            return ApiService.getDeliveryAddressListForCurrentUser()
                .then(response => {
                        this.listData = response.data
                        return response
                    }
                )
        },
        setCurrentDeliveryAddress(id) {
            return ApiService.setCurrentDeliveryAddress(id).then((response) => {
                this.listData = response.data
                return response
            })
        },
        editDeliveryAddress(data) {
            return ApiService.editDeliveryAddress(data).then((response) => {
                return response
            })
        },
        getItem(addressId) {
            let addressItem = {}
            this.listData.items.forEach((item, index) => {
                let itemAddressId = parseInt(item.Id)
                if (itemAddressId === addressId) {
                    addressItem = item
                    return
                }
            })

            return addressItem
        },
        deleteDeliveryAddress(addressId) {
            return ApiService.deleteDeliveryAddress(addressId).then((response) => {
                this.listData = response.data
                return response
            })
        },
        setTempDeliveryAddress(formData) {
            return ApiService.setTempDeliveryAddress(formData).then((response) => {
                if (response.data.success) {
                    this.tempDeliveryAddress = formData
                }
                return response
            })
        },
        setTempDeliveryAddressSelected(isSelected) {
            return ApiService.setTempDeliveryAddressSelected(isSelected).then((response) => {
                if (response.data.success) {
                    this.tempDeliveryAddress.isSelected = isSelected
                }
            })
        },
        loadTempDeliveryAddress() {
            return ApiService.getTempDeliveryAddress().then((response) => {
                this.tempDeliveryAddress = response.data.address
                console.log('loaded',this.tempDeliveryAddress)
                if (!this.tempDeliveryAddress.CountryCode || this.tempDeliveryAddress.CountryCode.trim() === '') {
                    this.tempDeliveryAddress.CountryCode = 'DE';
                }
                return response
            })
        }
    }
})
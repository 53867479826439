<template>
  <div class="uk-card uk-card-secondary ws-form-order-container uk-margin-medium-bottom">
    <div class="uk-card-header uk-padding-remove-bottom">
      <div class="ws-card-headline uk-h2">Lieferadresse</div>
    </div>
    <div class="uk-card-body">
      <form
          name="DeliveryAddressEditFormType"
          @submitt="submitForm"
      >
        <div class="uk-grid-small" uk-grid>
          <BaseInput
              :label="'Vorname'"
              v-model="Firstname"
              :error-message="formErrors.Firstname"
              :container-class="'uk-width-1-1 uk-width-1-2@m'"
              :required="false"
          ></BaseInput>
          <BaseInput
              :label="'Nachname'"
              v-model="Lastname"
              :error-message="formErrors.Lastname"
              :container-class="'uk-width-1-1 uk-width-1-2@m'"
          ></BaseInput>
          <BaseInput
              :label="'Firma'"
              v-model="Company"
              :error-message="formErrors.Company"
              :container-class="'uk-width-1-1'"
              :required="false"
          ></BaseInput>
          <BaseInput
              :label="'Straße'"
              v-model="Street"
              :error-message="formErrors.Street"
              :container-class="'uk-width-1-1 uk-width-3-4@m'"
          ></BaseInput>
          <BaseInput
              :label="'Hausnummer'"
              v-model="Housenumber"
              :error-message="formErrors.Housenumber"
              :container-class="'uk-width-1-1 uk-width-1-4@m'"
          ></BaseInput>
          <BaseInput
              :label="'PLZ'"
              :placeholder="'1234(5)'"
              v-model="Zip"
              :error-message="formErrors.Zip"
              :container-class="'uk-width-1-1 uk-width-1-3@m'"
          ></BaseInput>
          <BaseInput
              :label="'Ort'"
              v-model="City"
              :error-message="formErrors.City"
              :container-class="'uk-width-1-1 uk-width-2-3@m'"
          ></BaseInput>
          <BaseInput
              :label="'Telefon'"
              :placeholder="'+49 123 456789'"
              v-model="PhoneNumber"
              :error-message="formErrors.PhoneNumber"
              :container-class="'uk-width-1-1'"
              :required="false"
          ></BaseInput>
          <BaseInput
              :label="'E-Mail'"
              :placeholder="'E-Mail'"
              v-model="Email"
              :error-message="formErrors.Email"
              :container-class="'uk-width-1-1'"
              :required="false"
          ></BaseInput>
          <BaseSelect
              :label="'Land'"
              :option-list="CountryCodeList"
              v-model="CountryCode"
              :error-message="formErrors.CountryCode"
              :container-class="'uk-width-1-1'"
          ></BaseSelect>
        </div>

        <div class="uk-margin-medium-top">
          <button
              class="uk-button uk-button-primary"
              @click="submitForm"
          >{{ $t('general.save') }}
          </button>
          <div
              class="uk-button uk-button-default uk-margin-small-left"
              @click="hideDialog"
          >{{ $t('general.cancel') }}
          </div>
        </div>
        <input
            type="hidden"
            :value="Id"
        >
      </form>
    </div>
  </div>
</template>

<script>
import {useDeliveryAddressStore} from "../store/DeliveryAddressStore";
import ApiService from "../service/ApiService.js";
import {useField, useForm} from "vee-validate";
import {object, string, number, boolean} from 'yup'
import BaseInput from "./BaseInput.vue";
import BaseSelect from "./BaseSelect.vue";

export default {
  name: "DeliveryAddressForm",
  components: {BaseSelect, BaseInput},
  props: {
    updateParentList: {
      type: Function,
      default: () => {
      }
    },
    listStore: Object,
    require: true,
    tempDeliveryAddress: false
  },
  data() {
    return {
      CountryCodeList: [],
      _submit: '1'
    }
  },
  setup(props) {
    let emailValidation = string().email()
    let phoneNumberValidation = string()
    if(props.tempDeliveryAddress) {
      emailValidation = emailValidation.required()
      phoneNumberValidation = phoneNumberValidation.required()
    }

    const validationSchema = object({
      Firstname: string(),
      Street: string().required().min(3),
      Housenumber: string().required(),
      Zip: string().required().min(4).max(5).matches(/^\d{4,5}$/),
      City: string().required().min(3),
      CountryCode: string().required(),
      PhoneNumber: phoneNumberValidation,
      Email: emailValidation,
      Id: number().required()
    }).shape({
      Lastname: string().test(
          'at-least-one',
          'Vor- und Nachname oder Firma ausfüllen',
          function (value) {
            const {Company} = this.parent
            return Boolean(value || Company)
          }
      ),
      Company: string().test(
          'at-least-one',
          'Vor- und Nachname oder Firma ausfüllen',
          function (value) {
            const {Lastname} = this.parent
            return Boolean(value || Lastname)
          }
      )
    }).test('at-least-one', null, function (value) {
      if (!value.Company && !value.Lastname) {
        this.createError({
          path: 'Company',
          message: 'Min. eines dieser Felder ausfüllen',
        }) || this.createError({
          path: 'Lastname',
          message: 'Min. eines dieser Felder ausfüllen',
        });
      }

      return true
    })
    const {handleSubmit, errors} = useForm({
      validationSchema: validationSchema
    })

    const {value: Firstname} = useField('Firstname')
    const {value: Lastname} = useField('Lastname')
    const {value: Company} = useField('Company')
    const {value: Street} = useField('Street')
    const {value: Housenumber} = useField('Housenumber')
    const {value: Zip} = useField('Zip')
    const {value: City} = useField('City')
    const {value: CountryCode} = useField('CountryCode')
    const {value: PhoneNumber} = useField('PhoneNumber')
    const {value: Email} = useField('Email')
    const {value: Id} = useField('Id')

    const submitForm = handleSubmit(values => {
      console.log('v',values)
      if(!props.tempDeliveryAddress) {
        props.listStore.editDeliveryAddress(values).then((result) => {
          props.listStore.loadDeliveryAddressList()
          UIkit.modal("#deliveryAddressFormContainer").hide()
        })
      } else {
        props.listStore.setTempDeliveryAddress(values).then((result) => {
          UIkit.modal("#deliveryAddressFormContainer").hide()
        })
      }
    })

    return {
      Firstname,
      Lastname,
      Company,
      Street,
      Housenumber,
      Zip,
      City,
      CountryCode,
      PhoneNumber,
      Email,
      Id,
      formErrors: errors,
      submitForm
    }
  },
  methods: {
    prefillForm(data, countryCodeList) {
      this.Firstname = data.Firstname ?? ''
      this.Lastname = data.Lastname ?? ''
      this.Company = data.Company ?? ''
      this.Zip = data.Zip ?? ''
      this.Street = data.Street ?? ''
      this.Housenumber = data.Housenumber ?? ''
      this.City = data.City ?? ''
      this.CountryCode = data.CountryCode ?? ''
      this.PhoneNumber = data.PhoneNumber ?? ''
      this.Email = data.Email ?? ''
      this.Id = data.Id ?? 0
      this.CountryCodeList = countryCodeList ?? []
    },
    hideDialog() {
      UIkit.modal("#deliveryAddressFormContainer").hide()
    }
  }
}
</script>

<template>
  <div class="ws-cart-button-container ws-input-button-container uk-grid-small uk-flex uk-flex-middle" uk-grid>
    <div class="uk-width-auto ws-cart-button uk-padding-remove" @click="this.addToCart" uk-tooltip="In den Warenkorb legen">
      <i class="fa-regular fa-cart-circle-plus"></i>
    </div>
    <div class="">
      <input @focus="$event.target.select()" @keydown="checkNumberInput" @keyup="checkOnUp" class="ws-number-input uk-text-right" :class="dynWidth" v-model="itemsCount" type="text" :name="'productCount-' + this.pid" uk-tooltip="Anzahl ändern">
    </div>
  </div>
</template>

<script>
import {useCartStore} from "../store/CartStore"
import UIkit from "uikit";

export default {
  name: 'CartButton',
  data() {
    return {
      itemsCount: 1,
      maxItemsCount: 9999,
      dynWidth: 'ws-w-1',
      overlay: UIkit.modal('<div class="uk-modal uk-flex-top"><div class="ws-no-background uk-modal-dialog uk-text-center uk-margin-auto-vertical" id="' + this.getOverlayId() + '"><i style="font-size: 5rem" class="fa-regular fa-cart-arrow-down"></i></div></div>"')
    }
  },
  setup() {
    const cartStore = useCartStore()
    return {
      cartStore
    }
  },
  methods: {
    addToCart() {
      this.overlay.show();
      this.cartStore.addCartItem({pid: this.pid, cartItemsCount: this.itemsCount}).then((response) => {
        let to = 1000;
        let data = response.data;
        if(! data.success) {
          document.getElementById(this.getOverlayId()).innerHTML = '<span class="uk-text-danger uk-text-bold uk-text-large">' + data.message + '</div>';
          to = 5000
        }
        setTimeout(() => {
          this.overlay.hide()
        }, to)
      })
    },
    checkNumberInput(event) {
      var allowedKeys = [8, 37, 39, 13]
      var key = event.keyCode || event.charCode;

      if (isNaN(parseInt(event.key)) && allowedKeys.indexOf(key) == -1) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    checkOnUp(event) {
      var key = event.keyCode || event.charCode;
      if (key == 13) {
        this.addToCart()
      }
    },
    getOverlayId() {
      return 'o-' + this.pid
    }
  },
  watch: {
    itemsCount: {
      handler(valNew, valBefore) {
        if (valNew > this.maxItemsCount) {
          this.itemsCount = this.maxItemsCount
        }

        this.dynWidth = 'ws-w-1'
        if (this.itemsCount >= 10 && this.itemsCount < 100) {
          this.dynWidth = 'ws-w-2'
        }
        if (this.itemsCount >= 100 && this.itemsCount < 1000) {
          this.dynWidth = 'ws-w-3'
        }
        if (this.itemsCount >=  1000) {
          this.dynWidth = 'ws-w-4'
        }
      }
    }
  }
};
</script>

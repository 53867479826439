<template>
  <div>
    <div
        v-show="tempSelectedState"
        @click="showEditform"
    ><p class="uk-link uk-margin-small-top">Einmal-Lieferadresse ändern <i class="fa-regular fa-pen-to-square"></i></p>
    </div>
    <div id="deliveryAddressFormContainer" uk-modal>
      <div class="ws-container-medium">
        <DeliveryAddressForm :temp-delivery-address="true" :list-store="DeliveryAddressStore" ref="deliveryAddressForm"></DeliveryAddressForm>
      </div>
    </div>
  </div>
</template>

<script>
import DeliveryAddressForm from "./DeliveryAddressForm.vue";
import {useDeliveryAddressStore} from "../store/DeliveryAddressStore";

export default {
  name: 'TempDeliveryAddressHandler',
  components: {DeliveryAddressForm},
  data() {
    return {
      tempSelectedState: false,
    }
  },
  setup() {
    const DeliveryAddressStore = useDeliveryAddressStore()
    return {
      DeliveryAddressStore
    }
  },
  mounted() {
    this.DeliveryAddressStore.loadDeliveryAddressList()
    this.DeliveryAddressStore.loadTempDeliveryAddress().then((response) => {
      const select = document.getElementById("DeliveryAddressId");

      const newOption = document.createElement("option");
      newOption.value = "temp";
      const text = this.DeliveryAddressStore.getTempDeliveryAddressOptionString
      if (text) {
        newOption.textContent = text + ' (*)';
      } else {
        newOption.textContent = "Einmal-Lieferadresse";
      }

      if (this.tempSelected == 1) {
        this.tempSelectedState = true;
        newOption.selected = true;
      }
      select.appendChild(newOption);
      select.addEventListener("change", this.handleSelectChange);
    })
  },
  beforeUnmount() {
    const select = document.getElementById("DeliveryAddressId");
    select.removeEventListener("change", this.handleSelectChange);
  },

  methods: {
    handleSelectChange(event) {
      const selectedValue = event.target.value;
      if (selectedValue == "temp") {
        this.tempSelectedState = true;
        if (!this.DeliveryAddressStore.hasTempDeliveryAddress) {
          this.showEditform();
        }
      } else {
        this.tempSelectedState = false;
      }
      this.DeliveryAddressStore.setTempDeliveryAddressSelected(this.tempSelectedState)
    },
    showEditform() {
      let countryCodeList = this.DeliveryAddressStore.getCountryCodeList
      this.$refs.deliveryAddressForm.prefillForm(this.DeliveryAddressStore.getTempDeliveryAddress, countryCodeList)
      UIkit.modal("#deliveryAddressFormContainer").show();
    }
  },
  watch: {
    'DeliveryAddressStore.getTempDeliveryAddressOptionString': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        const select = document.getElementById("DeliveryAddressId");
        const tempOption = Array.from(select.options).find(option => option.value === "temp");

        if (tempOption) {
          tempOption.textContent = newValue ? newValue + ' (*)' : "Einmal-Lieferadresse";
        }
      }
    }
  }
};
</script>

<template>
  <div class="ws-input-button-container ws-cart-amount-container uk-grid-collapse uk-flex-inline uk-flex-middle uk-width-auto uk-child-width-auto" uk-grid>
    <div class="ws-amount-button uk-padding-remove-left ws-padding-xsmal-right" @click="addItem">
      <i class="fa-sharp fa-solid fa-circle-plus"></i>
    </div>
    <div class="">
      <input @focus="$event.target.select()" @keydown="checkNumberInput" @keyup="checkOnUp" class="ws-number-input uk-text-right" :class="dynWidth" v-model="itemsCount" type="text" :name="'items[' + this.dataItemId + '][count]'" uk-tooltip="Anzahl ändern">
    </div>
    <div class="ws-amount-button ws-padding-xsmal-left">
      <i class="fa-sharp fa-solid fa-circle-minus" @click="subItem"></i>
    </div>
  </div>
</template>

<script>
import {useCartStore} from "../store/CartStore"
import UIkit from "uikit";

export default {
  name: 'CartAmountButton',
  data() {
    return {
      itemsCount: 1,
      maxItemsCount: 9999,
      dynWidth: 'ws-w-1'
    }
  },
  setup() {
    const cartStore = useCartStore()
    return {
      cartStore
    }
  },
  methods: {
    addItem() {
      if (this.itemsCount < this.maxItemsCount) {
        this.itemsCount = parseInt(this.itemsCount) + 1;
      }
    },
    subItem() {
      if (this.itemsCount > 1) {
        this.itemsCount = this.itemsCount - 1;
      }
    },
    checkNumberInput(event) {
      var allowedKeys = [8, 37, 39, 13]
      var key = event.keyCode || event.charCode;

      if (isNaN(parseInt(event.key)) && allowedKeys.indexOf(key) == -1) {
        event.preventDefault();
        event.stopPropagation();
      }
    },
    checkOnUp(event) {
      var key = event.keyCode || event.charCode;
      if (key == 13) {
        //this.submit()
      }
    }
  },
  mounted() {
    this.itemsCount = this.dataItemCount;
  },
  watch: {
    itemsCount: {
      handler(valNew, valBefore) {
        if (valNew > this.maxItemsCount) {
          this.itemsCount = this.maxItemsCount
        }
        this.dynWidth = 'ws-w-1'
        if (this.itemsCount >= 10 && this.itemsCount < 100) {
          this.dynWidth = 'ws-w-2'
        }
        if (this.itemsCount >= 100 && this.itemsCount < 1000) {
          this.dynWidth = 'ws-w-3'
        }
        if (this.itemsCount >= 1000) {
          this.dynWidth = 'ws-w-4'
        }
      }
    }
  }
};
</script>
